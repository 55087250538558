import React, { useContext, useState } from 'react';
import { FichaContext } from '../../../../context/FichaContext';
import { TextField, Button, Grid, Typography, Tooltip } from '@mui/material';
import { Ficha } from '../typesFicha';
import './Componentes.css';
import { InfoOutlined } from '@mui/icons-material';

interface Props {
  onSave: (componentName: string, data: Partial<Ficha>) => void;
  onExit: (componentName: string, data: Partial<Ficha>) => void;
  setLoading: (isLoading: boolean) => void;
  isLoading: boolean;
}

const CarteiraDeTrabalho: React.FC<Props> = ({ onSave, onExit, setLoading, isLoading }) => {
  const fichaContext = useContext(FichaContext);
  const cpf = fichaContext?.ficha.cpf || '';

  const [formData, setFormData] = useState({
    numeroCtps: '',
    numeroSerieCtps: '',
    numeroPisPasep: fichaContext?.ficha.numeroPisPasep || '',
  });

  React.useEffect(() => {
    if (cpf) {
      const unmaskedCpf = cpf.replace(/\D/g, '');
      setFormData({
        ...formData,
        numeroCtps: unmaskedCpf.slice(0, 7),
        numeroSerieCtps: unmaskedCpf.slice(7),
      });
    }
  }, [cpf]);

  if (!fichaContext) {
    return null;
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const sanitizePisPasep = (pis: string): string => {
    return pis.replace(/\D/g, '');
  };

  const handleSave = () => {
    const sanitizedFormData = {
      ...formData,
      numeroPisPasep: sanitizePisPasep(formData.numeroPisPasep),
    };
    onSave('carteiraDeTrabalho', sanitizedFormData);
  };

  const handleExit = () => {
    const sanitizedFormData = {
      ...formData,
      numeroPisPasep: sanitizePisPasep(formData.numeroPisPasep),
    };
    onExit('onExit', sanitizedFormData);
  };

  const disableSaveButton = () => {
    const { numeroCtps, numeroSerieCtps, numeroPisPasep } = formData;
    return !numeroCtps || !numeroSerieCtps || !numeroPisPasep;
  };

  return (
    <>
      <Grid style={{ marginRight: 16, marginLeft: 16, marginBottom: 16 }}>
        <Typography variant="h6" className="titleComponente">
          CTPS
        </Typography>
        <Typography variant="body2" className="descriptionComponente">
          Etapa obrigatória <span className='asterisk'>*</span>
        </Typography>
      </Grid>
      <form className="formComponente">
        <Grid className="gridComponente">
          <Typography variant="h6" className="titleTooltip">
            <span>Número CTPS <span className='asterisk'>*</span></span>
            <Tooltip style={{ cursor: 'pointer' }} title="Atualizado automaticamente a partir dos 7 primeiros dígitos do CPF." arrow>
              <InfoOutlined className="infoOutlined" />
            </Tooltip>
          </Typography>
          <TextField
            name="numeroCtps"
            value={formData.numeroCtps}
            onChange={handleChange}
            label=""
            size="small"
            inputProps={{ maxLength: 40 }}
            required
            className="textFieldDisabled"
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>

        <Grid className="gridComponente">
          <Typography variant="h6" className="titleTooltip">
            <span>Número de série CTPS <span className='asterisk'>*</span></span>
            <Tooltip style={{ cursor: 'pointer' }} title="Atualizado automaticamente a partir dos 4 primeiros dígitos do CPF." arrow>
              <InfoOutlined className="infoOutlined" />
            </Tooltip>
          </Typography>
          <TextField
            name="numeroSerieCtps"
            value={formData.numeroSerieCtps}
            onChange={handleChange}
            label=""
            inputProps={{ maxLength: 60 }}
            size="small"
            required
            className="textFieldDisabled"
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>

        <Grid className="gridComponente">
          <Typography variant="h6" className="titleTypography">
            Número do PIS/PASEP <span className='asterisk'>*</span>
          </Typography>
          <TextField
            name="numeroPisPasep"
            value={formData.numeroPisPasep}
            onChange={handleChange}
            label=""
            inputProps={{ maxLength: 50 }}
            size="small"
            required
            className="textField"
          />
        </Grid>
      </form>
      <div className='divButtons'>
        <Button
          className='buttonSaveNext'
          variant="contained"
          onClick={handleSave}
          disabled={disableSaveButton() || isLoading}
        >
          Próxima Etapa
        </Button>
        <Button
          className='buttonSaveExit'
          variant="contained"
          onClick={handleExit}
          disabled={disableSaveButton() || isLoading}
        >
          Salvar e Continuar Depois
        </Button>
      </div>
    </>
  );
};

export default CarteiraDeTrabalho;
