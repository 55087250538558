import React, { useState, useContext, useEffect } from 'react';
import { FichaContext } from '../../../../context/FichaContext';
import {
  TextField,
  Button,
  Grid,
  Typography
} from '@mui/material';
import { Ficha } from '../typesFicha';
import './Componentes.css';
import { verificaEleitor } from '../../../../helpers/validationFicha';
import { formatDateToBrazilianPattern } from '../../../../helpers/format';

interface Props {
  onSave: (componentName: string, data: Partial<Ficha>) => void;
  onExit: (componentName: string, data: Partial<Ficha>) => void;
  setLoading: (isLoading: boolean) => void;
  isLoading: boolean;
}

const TituloDeEleitor: React.FC<Props> = ({ onSave, onExit, setLoading, isLoading }) => {
  const fichaContext = useContext(FichaContext);
  const [formData, setFormData] = useState({
    numeroTituloEleitor: fichaContext?.ficha.numeroTituloEleitor || '',
    dataTituloEleitor: fichaContext?.ficha.dataTituloEleitor || '',
    zonaEleitoral: fichaContext?.ficha.zonaEleitoral || '',
    secaoEleitoral: fichaContext?.ficha.secaoEleitoral || '',
  });

  useEffect(() => {
    if (!fichaContext) return;
    const { ficha } = fichaContext;

    const formatDateForInput = (date: string | null | undefined): string => {
      if (!date) return '';
      const [day, month, year] = date.split('/');
      return `${year}-${month}-${day}`;
    };

    setFormData((prevState) => ({
      ...prevState,
      dataTituloEleitor: formatDateForInput(ficha.dataTituloEleitor || ''),
    }));
  }, [fichaContext]);

  if (!fichaContext) {
    return null;
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const isDateInBrazilianFormat = (date: string): boolean => {
    return /^\d{2}\/\d{2}\/\d{4}$/.test(date);
  };

  const handleSave = () => {
    const dadosParaSalvar = {
      ...formData,
      dataTituloEleitor: formData.dataTituloEleitor && !isDateInBrazilianFormat(formData.dataTituloEleitor) ? formatDateToBrazilianPattern(formData.dataTituloEleitor) : formData.dataTituloEleitor,
    }
    onSave('tituloDeEleitor', dadosParaSalvar);
  };

  const handleExit = () => {
    const dadosParaSair = {
      ...formData,
      dataTituloEleitor: formData.dataTituloEleitor && !isDateInBrazilianFormat(formData.dataTituloEleitor) ? formatDateToBrazilianPattern(formData.dataTituloEleitor) : formData.dataTituloEleitor,
    };
    onExit('onExit', dadosParaSair);
  };

  const isTituloEleitorDisabled = () => {
    const { dtNascimento, nacionalidade } = fichaContext.ficha;
    return !verificaEleitor(dtNascimento, nacionalidade);
  };

  const areFieldsFilled = () => {
    const { numeroTituloEleitor, dataTituloEleitor, zonaEleitoral, secaoEleitoral } = formData;
    return numeroTituloEleitor !== '' && dataTituloEleitor !== '' && zonaEleitoral !== '' && secaoEleitoral !== '';
  };

  return (
    <>
      <Grid style={{ marginRight: 16, marginLeft: 16, marginBottom: 16 }}>
        <Typography variant="h6" className='titleComponente'>
          Título de eleitor
        </Typography>
        <Typography variant="body2" className='descriptionComponente'>
          {!isTituloEleitorDisabled() ? (
            <>
              Preenchimento obrigatório <span className="asterisk">*</span>
            </>
          ) : (
            <span className="asterisk">Candidato dispensado dessa etapa, pode salvar e concluir.</span>
          )}
        </Typography>
      </Grid>
      <form className='formComponente'>
        <Grid className='gridComponente'>
          <Typography variant="h6" className='titleTypography'>
            Número de inscrição <span className='asterisk'>*</span>
          </Typography>
          <TextField
            name="numeroTituloEleitor"
            value={formData.numeroTituloEleitor}
            onChange={handleChange}
            label=""
            size='small'
            required
            className={!isTituloEleitorDisabled() ? 'textField' : 'textFieldDisabled'}
            inputProps={{ maxLength: 50 }}
            disabled={isTituloEleitorDisabled()}
          />
        </Grid>

        <Grid className='gridComponente'>
          <Typography variant="h6" className='titleTypography'>
            Data de emissão <span className='asterisk'>*</span>
          </Typography>
          <TextField
            name="dataTituloEleitor"
            value={formData.dataTituloEleitor}
            onChange={handleChange}
            type="date"
            label=""
            inputProps={{ maxLength: 50 }}
            size='small'
            required
            disabled={isTituloEleitorDisabled()}
            className={!isTituloEleitorDisabled() ? 'textField' : 'textFieldDisabled'}
          />
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6" className='titleTypography'>
              Zona eleitoral <span className='asterisk'>*</span>
            </Typography>
            <TextField
              name="zonaEleitoral"
              value={formData.zonaEleitoral}
              onChange={handleChange}
              label=""
              disabled={isTituloEleitorDisabled()}
              size='small'
              required
              inputProps={{ maxLength: 50 }}
              className={!isTituloEleitorDisabled() ? 'textField' : 'textFieldDisabled'}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" className='titleTypography'>
              Seção eleitoral <span className='asterisk'>*</span>
            </Typography>
            <TextField
              name="secaoEleitoral"
              value={formData.secaoEleitoral}
              onChange={handleChange}
              label=""
              size='small'
              disabled={isTituloEleitorDisabled()}
              required
              inputProps={{ maxLength: 10 }}
              className={!isTituloEleitorDisabled() ? 'textField' : 'textFieldDisabled'}
            />
          </Grid>
        </Grid>
      </form>
      <div className='divButtons'>
        <Button
          className='buttonSaveNext'
          variant="contained"
          onClick={handleSave}
          disabled={(!areFieldsFilled() && !isTituloEleitorDisabled()) || isLoading}
        >
          Próxima Etapa
        </Button>
        <Button className='buttonSaveExit' variant="contained" onClick={handleExit} disabled={(!areFieldsFilled() && !isTituloEleitorDisabled()) || isLoading}>
          Salvar e Continuar Depois
        </Button>
      </div>
    </>
  );
};

export default TituloDeEleitor;
