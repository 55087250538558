import React, { useContext, useState, useEffect } from 'react';
import { FichaContext } from '../../../../context/FichaContext';
import { TextField, Button, FormControl, RadioGroup, FormControlLabel, Radio, Grid, Typography, Select, MenuItem, CircularProgress } from '@mui/material';
import { Ficha } from '../typesFicha';
import { validateCpf } from '../../../../helpers/validation';
import { removeCpfFormat, formatDateToBrazilianPattern } from '../../../../helpers/format';
import './Componentes.css';
import { getEstadoNatal, getCidadeNatal, getNacionalidadeEstrangeira } from '../../../../services/api';
import { calculateAge } from '../../../../helpers/validationFicha';

interface Props {
  onSave: (componentName: string, data: Partial<Ficha>) => void;
  onExit: (componentName: string, data: Partial<Ficha>) => void;
  setLoading: (isLoading: boolean) => void;
  isLoading: boolean;
}

const DadosPessoais: React.FC<Props> = ({ onSave, onExit, setLoading, isLoading }) => {
  const fichaContext = useContext(FichaContext);
  const [formData, setFormData] = useState({
    nome: fichaContext?.ficha.nome || '',
    dtNascimento: fichaContext?.ficha.dtNascimento || '',
    cpf: fichaContext?.ficha.cpf || '',
    nacionalidade: (!!fichaContext?.ficha.nacionalidade) && fichaContext?.ficha.nacionalidade !== 'Brasileira' ? fichaContext?.ficha.nacionalidade : 'Brasileira',
    outraNacionalidade: fichaContext?.ficha.nacionalidade !== 'Brasileira' ? fichaContext?.ficha.nacionalidade : '',
    naturalidade: fichaContext?.ficha.naturalidade || '',
    estadoNatal: fichaContext?.ficha.estadoNatal || '',
    codeTD: fichaContext?.ficha.codeTD || '',
    registroNacionalEstrangeiros: fichaContext?.ficha.registroNacionalEstrangeiros || '',
    dataValidadeRegistroNacionalEstrangeiros: fichaContext?.ficha.dataValidadeRegistroNacionalEstrangeiros || '',
    numeroIdentidade: fichaContext?.ficha.numeroIdentidade || '',
    dataEmissaoIdentidade: fichaContext?.ficha.dataEmissaoIdentidade || '',
    estadoEmissorIdentidade: fichaContext?.ficha.estadoEmissorIdentidade || '',
    orgaoEmissorIdentidade: fichaContext?.ficha.orgaoEmissorIdentidade || '',
    numeroTituloEleitor: fichaContext?.ficha.numeroTituloEleitor || '',
    dataTituloEleitor: fichaContext?.ficha.dataTituloEleitor || '',
    zonaEleitoral: fichaContext?.ficha.zonaEleitoral || '',
    secaoEleitoral: fichaContext?.ficha.secaoEleitoral || '',
    numeroCertificadoReservista: fichaContext?.ficha.numeroCertificadoReservista || '',
    situacaoMilitar: fichaContext?.ficha.situacaoMilitar || '',
  });

  const [estados, setEstados] = useState<any[]>([]);
  const [cidades, setCidades] = useState<any[]>([]);
  const [nacionalidadeEstrangeira, setNacionalidadeEstrangeira] = useState<any>([]);
  const [estadosLoading, setEstadosLoading] = useState<boolean>(false);
  const [cidadesLoading, setCidadesLoading] = useState<boolean>(false);
  const [nacionalidadeEstrangeiraLoading, setNacionalidadeEstrangeiraLoading] = useState<boolean>(false);
  const [radioValue, setRadioValue] = useState<string>('Brasileira');

  useEffect(() => {
    const formatDateForInput = (date: string | null | undefined): string => {
      if (!date) return '';
      const [day, month, year] = date.split('/');
      return `${year}-${month}-${day}`;
    };

    setFormData((prevState) => ({
      ...prevState,
      dtNascimento: formatDateForInput(fichaContext?.ficha.dtNascimento || ''),
      dataValidadeRegistroNacionalEstrangeiros: formatDateForInput(fichaContext?.ficha.dataValidadeRegistroNacionalEstrangeiros || ''),
    }));
  }, [fichaContext]);


  useEffect(() => {
    const dataNascimento = new Date(formData.dtNascimento);
    const idade = calculateAge(dataNascimento);
    if (formData.nacionalidade !== 'Brasileira') {
      setFormData((prevState) => ({
        ...prevState,
        numeroIdentidade: '',
        dataEmissaoIdentidade: '',
        estadoEmissorIdentidade: '',
        orgaoEmissorIdentidade: '',
        numeroTituloEleitor: '',
        dataTituloEleitor: '',
        zonaEleitoral: '',
        secaoEleitoral: '',
        numeroCertificadoReservista: '',
        situacaoMilitar: '',
      }));
    }

    if (idade < 18 || idade > 70) {
      setFormData((prevState) => ({
        ...prevState,
        numeroTituloEleitor: '',
        dataTituloEleitor: '',
        zonaEleitoral: '',
        secaoEleitoral: '',
      }));
    }

    if (idade < 18 || idade > 45) {
      setFormData((prevState) => ({
        ...prevState,
        numeroCertificadoReservista: '',
        situacaoMilitar: '',
      }));
    }
  }, [formData.nacionalidade, formData.dtNascimento]);

  useEffect(() => {
    if (formData.nacionalidade && formData.nacionalidade !== 'Brasileira') {
      setRadioValue('Outra');
    } else {
      setRadioValue('Brasileira');
    }
  }, [formData.nacionalidade]);

  useEffect(() => {
    const fetchNacionalidadeEstrangeira = async () => {
      setNacionalidadeEstrangeiraLoading(true);
      const data = await getNacionalidadeEstrangeira();
      if (Array.isArray(data)) {
        setNacionalidadeEstrangeira(data);
      } else {
        setNacionalidadeEstrangeira([]);
      }
      setNacionalidadeEstrangeiraLoading(false);
    };
    fetchNacionalidadeEstrangeira();
  }, [formData.nacionalidade]);

  useEffect(() => {
    const fetchEstados = async () => {
      setEstadosLoading(true);
      const data = await getEstadoNatal();
      if (Array.isArray(data)) {
        setEstados(data);
      }
      setEstadosLoading(false);
    };
    fetchEstados();
  }, []);

  useEffect(() => {
    const fetchCidades = async () => {
      if (formData.codeTD) {
        setCidadesLoading(true);
        const data = await getCidadeNatal(formData.codeTD);
        if (Array.isArray(data)) {
          setCidades(data);
        }
        setCidadesLoading(false);
      }
    };
    fetchCidades();
  }, [formData.codeTD]);


  if (!fichaContext) {
    return null;
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleEstadoNatalChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedEstado = estados.find((estado) => estado.codetd === event.target.value);
    setFormData((prevState) => ({
      ...prevState,
      estadoNatal: selectedEstado?.nome || '',
      codeTD: selectedEstado?.codetd || '',
      naturalidade: '',
    }));
    setCidades([]);
  };

  const handleCidadeNatalChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedCidade = cidades.find((cidade) => cidade.nomemunicipio === event.target.value);
    setFormData((prevState) => ({
      ...prevState,
      naturalidade: selectedCidade?.nomemunicipio || '',
    }));
  };

  const handleNacionalidadeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setRadioValue(value);
    setFormData((prevState) => ({
      ...prevState,
      nacionalidade: value,
      naturalidade: value === 'Brasileira' ? formData.naturalidade : '',
      codeTD: value === 'Brasileira' ? formData.codeTD : '',
      estadoNatal: value === 'Brasileira' ? formData.estadoNatal : '',
      registroNacionalEstrangeiros: value === 'Outra' ? formData.registroNacionalEstrangeiros : '',
      dataValidadeRegistroNacionalEstrangeiros: value === 'Outra' ? formData.dataValidadeRegistroNacionalEstrangeiros : '',
      outraNacionalidade: value === 'Outra' ? formData.outraNacionalidade : '',
    }));
  };

  const handleNacionalidadeEstrangeiraChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const paisNatal = nacionalidadeEstrangeira.find((pais: { descricao: string }) => pais.descricao === event.target.value);
    console.log(paisNatal?.descricao, "paisNatal");
    setFormData((prevState) => ({
      ...prevState,
      outraNacionalidade: paisNatal?.descricao || '',
    }));

    if (paisNatal?.descricao === 'Brasileira') {
      setFormData((prevState) => ({
        ...prevState,
        naturalidade: '',
        estadoNatal: '',
      }));
    }

    if (paisNatal?.descricao === 'Outra') {
      console.log('Outra nacionalidade selecionada', paisNatal);
      setFormData((prevState) => ({
        ...prevState,
        registroNacionalEstrangeiros: '',
        dataValidadeRegistroNacionalEstrangeiros: '',
      }));
    }
  };


  const isDateInBrazilianFormat = (date: string): boolean => {
    return /^\d{2}\/\d{2}\/\d{4}$/.test(date);
  };

  const handleSave = () => {
    const nacionalidade = formData.nacionalidade === 'Outra' && formData.outraNacionalidade !== ''
      ? formData.outraNacionalidade
      : formData.nacionalidade;

    const cpfSemFormatacao = removeCpfFormat(formData.cpf);

    const dadosParaSalvar = {
      ...formData,
      cpf: cpfSemFormatacao,
      nome: formData.nome.toUpperCase(),
      nacionalidade,
      dtNascimento: formData.dtNascimento && !isDateInBrazilianFormat(formData.dtNascimento) ? formatDateToBrazilianPattern(formData.dtNascimento) : formData.dtNascimento,
      dataValidadeRegistroNacionalEstrangeiros: formData.dataValidadeRegistroNacionalEstrangeiros && !isDateInBrazilianFormat(formData.dataValidadeRegistroNacionalEstrangeiros) ? formatDateToBrazilianPattern(formData.dataValidadeRegistroNacionalEstrangeiros) : formData.dataValidadeRegistroNacionalEstrangeiros,
      numeroIdentidade: formData.numeroIdentidade || undefined,
      estadoEmissorIdentidade: formData.estadoEmissorIdentidade || undefined,
      orgaoEmissorIdentidade: formData.orgaoEmissorIdentidade || undefined,
      numeroTituloEleitor: formData.numeroTituloEleitor || undefined,
      zonaEleitoral: formData.zonaEleitoral || undefined,
      secaoEleitoral: formData.secaoEleitoral || undefined,
      numeroCertificadoReservista: formData.numeroCertificadoReservista || undefined,
      situacaoMilitar: formData.situacaoMilitar || undefined,
    };

    onSave('dadosPessoais', dadosParaSalvar);
  };

  const handleExit = () => {
    const nacionalidade = formData.nacionalidade === 'Outra' && formData.outraNacionalidade !== ''
      ? formData.outraNacionalidade
      : formData.nacionalidade;

    const cpfSemFormatacao = removeCpfFormat(formData.cpf);

    const dadosParaSair = {
      ...formData,
      cpf: cpfSemFormatacao,
      nome: formData.nome.toUpperCase(),
      nacionalidade,
      dtNascimento: formData.dtNascimento && !isDateInBrazilianFormat(formData.dtNascimento) ? formatDateToBrazilianPattern(formData.dtNascimento) : formData.dtNascimento,
      dataValidadeRegistroNacionalEstrangeiros: formData.dataValidadeRegistroNacionalEstrangeiros && !isDateInBrazilianFormat(formData.dataValidadeRegistroNacionalEstrangeiros) ? formatDateToBrazilianPattern(formData.dataValidadeRegistroNacionalEstrangeiros) : formData.dataValidadeRegistroNacionalEstrangeiros,
      numeroIdentidade: formData.numeroIdentidade || undefined,
      estadoEmissorIdentidade: formData.estadoEmissorIdentidade || undefined,
      orgaoEmissorIdentidade: formData.orgaoEmissorIdentidade || undefined,
      numeroTituloEleitor: formData.numeroTituloEleitor || undefined,
      zonaEleitoral: formData.zonaEleitoral || undefined,
      secaoEleitoral: formData.secaoEleitoral || undefined,
      numeroCertificadoReservista: formData.numeroCertificadoReservista || undefined,
      situacaoMilitar: formData.situacaoMilitar || undefined,
    };

    onExit('onExit', dadosParaSair);
  };

  const handleCpfValidation = () => {
    const cpfSemFormatacao = removeCpfFormat(formData.cpf);
    return cpfSemFormatacao.length > 0 ? !validateCpf(cpfSemFormatacao) : false;
  };

  const handleCpfChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const unmaskedValue = value.replace(/\D/g, '');
    const formattedValue = unmaskedValue
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})$/, '$1-$2');

    setFormData({
      ...formData,
      cpf: formattedValue,
    });
  };

  const disableSaveButton = () => {
    const {
      nacionalidade,
      outraNacionalidade,
      registroNacionalEstrangeiros,
      dataValidadeRegistroNacionalEstrangeiros,
      naturalidade,
      estadoNatal,
      nome,
      dtNascimento,
      cpf,
    } = formData;

    const camposPreenchidos =
      nome !== '' &&
      dtNascimento !== '' &&
      cpf !== '' &&
      (nacionalidade === 'Brasileira' ? naturalidade !== '' : true) &&
      (nacionalidade === 'Brasileira' ? estadoNatal !== '' : true) &&
      (nacionalidade === 'Outra'
        ? outraNacionalidade !== '' &&
        registroNacionalEstrangeiros !== '' &&
        dataValidadeRegistroNacionalEstrangeiros !== ''
        : true);

    const isCpfValido = validateCpf(removeCpfFormat(cpf));

    if (nacionalidade === 'Brasileira') {
      return !camposPreenchidos || naturalidade === '' || estadoNatal === '' || !isCpfValido;
    } else {
      return !camposPreenchidos || outraNacionalidade === '' || registroNacionalEstrangeiros === '' || dataValidadeRegistroNacionalEstrangeiros === '';
    }
  };

  const isOutraNacionalidade = formData.nacionalidade === 'Outra';
  const isNonBrasileira = formData.nacionalidade !== 'Brasileira';
  const isNacionalidadeValida = formData.nacionalidade != null && formData.nacionalidade !== '';


  return (
    <>
      <Grid style={{ marginRight: 16, marginLeft: 16, marginBottom: 16 }}>
        <Typography variant="h6" className='titleComponente'>
          Dados pessoais
        </Typography>
        <Typography variant="body2" className='descriptionComponente'>
          Etapa obrigatória <span className='asterisk'>*</span>
        </Typography>
      </Grid>
      <form className='formComponente'>
        <Grid className='gridComponente'>
          <Typography variant="h6" className='titleTypography'>
            Nome completo <span className='asterisk'>*</span>
          </Typography>
          <TextField
            label=""
            size='small'
            required
            type='text'
            inputProps={{ maxLength: 90 }}
            name="nome"
            value={formData.nome}
            onChange={handleChange}
            className='textField'
          />
        </Grid>

        <Grid className='gridComponente'>
          <Typography variant="h6" className='titleTypography'>
            Data de nascimento <span className='asterisk'>*</span>
          </Typography>
          <TextField
            size='small'
            required
            name="dtNascimento"
            value={formData.dtNascimento}
            onChange={handleChange}
            inputProps={{ maxLength: 10 }}
            type="date"
            label=""
            className='textField'
          />
        </Grid>

        <Grid className='gridComponente'>
          <Typography variant="h6" className='titleTypography'>
            CPF <span className='asterisk'>*</span>
          </Typography>
          <TextField
            required
            size='small'
            name="cpf"
            value={formData.cpf}
            onChange={handleCpfChange}
            label=""
            error={handleCpfValidation()}
            helperText={handleCpfValidation() && "Digite um CPF válido."}
            inputProps={{ maxLength: 14 }}
            className='textField'
          />
        </Grid>

        <Grid className='gridComponenteRadio'>
          <Typography variant="h6" className='titleTypography'>
            Nacionalidade <span className='asterisk'>*</span>
          </Typography>
          <FormControl className='formControl' component="fieldset" required>
            <RadioGroup className='radioGroup' row name="nacionalidade" value={radioValue} onChange={handleNacionalidadeChange}>
              <FormControlLabel className='formControlLabel' value="Brasileira" control={<Radio />} label="Brasileira" />
              <FormControlLabel className='formControlLabel' value="Outra" control={<Radio />} label="Outra:" />
            </RadioGroup>
          </FormControl>
        </Grid>

        {(isOutraNacionalidade || (isNonBrasileira && isNacionalidadeValida)) && (
          <>
            <Grid className='gridComponente'>
              <Typography variant="h6" className='titleTypography'>
                Nacionalidade estrangeira <span className='asterisk'>*</span>
              </Typography>

              <FormControl fullWidth className='formControl' required>
                <Select
                  size='small'
                  name="outraNacionalidade"
                  label=""
                  displayEmpty
                  className='selectField'
                  disabled={nacionalidadeEstrangeiraLoading}
                  value={formData.outraNacionalidade || formData.nacionalidade}
                  onChange={(e) => {
                    handleNacionalidadeEstrangeiraChange(e as any);
                  }}
                >
                  <MenuItem value={"Outra"} disabled>
                    Selecione um País
                  </MenuItem>
                  {
                    nacionalidadeEstrangeira.map((pais: { idpais: number, descricao: string }) => (
                      <MenuItem key={pais.idpais} value={pais.descricao}>
                        {pais.descricao}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>

            <Grid className='gridComponente'>
              <Typography variant="h6" className='titleTypography'>
                RNE <span className='asterisk'>*</span>
              </Typography>
              <TextField
                size='small'
                required
                name="registroNacionalEstrangeiros"
                value={formData.registroNacionalEstrangeiros}
                onChange={handleChange}
                label=""
                inputProps={{ maxLength: 60 }}
                className='textField'
              />
            </Grid>

            <Grid className='gridComponente'>
              <Typography variant="h6" className='titleTypography'>
                Data de validade <span className='asterisk'>*</span>
              </Typography>
              <TextField
                size='small'
                required
                name="dataValidadeRegistroNacionalEstrangeiros"
                value={formData.dataValidadeRegistroNacionalEstrangeiros}
                inputProps={{ maxLength: 10 }}
                onChange={handleChange}
                type="date"
                label=""
                className='textField'
              />
            </Grid>
          </>
        )}

        {formData.nacionalidade === 'Brasileira' && (
          <>
            <Grid className='gridComponente'>
              <Typography variant="h6" className='titleTypography'>
                Estado de nascimento <span className='asterisk'>*</span>
              </Typography>

              <FormControl fullWidth className='formControl' required>
                <Select
                  size='small'
                  name="codeTD"
                  label=""
                  displayEmpty
                  className='selectField'
                  disabled={estadosLoading}
                  value={formData.codeTD}
                  onChange={(e) => {
                    handleEstadoNatalChange(e as any);
                  }}
                >
                  <MenuItem value="" disabled>
                    Selecione um Estado
                  </MenuItem>
                  {estados.map((estado) => (
                    <MenuItem key={estado.codetd} value={estado.codetd}>
                      {estado.nome}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid className='gridComponente'>
              <Typography variant="h6" className='titleTypography'>
                Cidade de nascimento <span className='asterisk'>*</span>
              </Typography>
              <FormControl className='formControl' fullWidth required>
                <Select
                  value={formData.naturalidade}
                  onChange={(e) => {
                    handleCidadeNatalChange(e as any);
                  }}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Cidade Natal' }}
                  disabled={!formData.estadoNatal || cidadesLoading}
                  className='selectField'
                  size='small'
                  name="naturalidade"
                  label=""
                >
                  {cidadesLoading ? (
                    <MenuItem value="" disabled>
                      Buscando cidades...
                      <CircularProgress size={10} />
                    </MenuItem>
                  ) : (
                    <MenuItem value="" disabled>
                      {!formData.estadoNatal ? 'Primeiro selecione um estado' : 'Selecione uma Cidade'}
                    </MenuItem>
                  )}
                  {cidades.map((cidade) => (
                    <MenuItem key={cidade.nomemunicipio} value={cidade.nomemunicipio}>
                      {cidade.nomemunicipio}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </>
        )}
      </form>

      <div className='divButtons'>
        <Button className='buttonSaveNext' variant="contained" onClick={handleSave} disabled={disableSaveButton() || isLoading}>
          Próxima Etapa
        </Button>
        <Button className='buttonSaveExit' variant="contained" onClick={handleExit} disabled={disableSaveButton() || isLoading}>
          Salvar e Continuar Depois
        </Button>
      </div>
    </>
  );
};

export default DadosPessoais;
