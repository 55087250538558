import React, { useState, useContext } from 'react';
import { ValeTransporte } from '../typesFicha';
import { Button, Grid, TextField, Typography, Modal } from '@mui/material';
import { FichaContext } from '../../../../context/FichaContext';
import { FiAlertCircle } from 'react-icons/fi';

interface ValeTransporteFormProps {
  title: string;
  initialData: ValeTransporte;
  onSave: (data: ValeTransporte) => void;
  onCancel: () => void;
  onDelete?: () => void;
}

const ValeTransporteForm: React.FC<ValeTransporteFormProps> = ({ title, initialData, onSave, onCancel, onDelete }) => {
  const context = useContext(FichaContext);
  const [formData, setFormData] = useState<ValeTransporte>(initialData);
  const [lineExists, setLineExists] = useState(false);
  const [isCancelLinhaModalOpen, setIsCancelLinhaModalOpen] = useState(false);

  if (!context) {
    return <div>Contexto não encontrado</div>;
  }

  const { ficha } = context;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === 'linha') {
      checkLineExists(value);
    }
  };

  const checkLineExists = (line: string) => {
    const exists = ficha.valeTransporte.some(vt => vt.linha === line);
    setLineExists(exists);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!lineExists) {
      onSave(formData);
    }
  };

  const confirmDeleteLinha = () => {
    setIsCancelLinhaModalOpen(true);
  };

  const handleDeleteLinha = () => {
    onDelete && onDelete();
    setIsCancelLinhaModalOpen(false);
  };

  const handleCloseModalLinha = () => {
    setIsCancelLinhaModalOpen(false);
  };

  return (
    <>
      <Grid style={{ marginRight: 16, marginLeft: 16, marginBottom: 16 }}>
        <Typography variant="h6" className='titleComponente'>
          {title}
        </Typography>
        <Typography variant="body2" className='descriptionComponente'>
          Etapa obrigatória <span className='asterisk'>*</span>
        </Typography>
      </Grid>
      <form onSubmit={handleSubmit}>
        <div className='formComponente'>
          <Grid className='gridComponente'>
            <Typography variant="h6" className='titleTypography'>
              Linha <span className='asterisk'>*</span>
            </Typography>
            <TextField
              size='small'
              required
              name="linha"
              value={formData.linha || ''}
              onChange={handleChange}
              error={lineExists}
              helperText={lineExists ? 'Esta linha já está cadastrada.' : ''}
              inputProps={{ maxLength: 10 }}
              label=""
              className='textField'
            />
          </Grid>
          <Grid className='gridComponente'>
            <Typography variant="h6" className='titleTypography'>
              Valor da Tarifa <span className='asterisk'>*</span>
            </Typography>
            <TextField
              size='small'
              required
              name="valorTarifa"
              value={formData.valorTarifa || ''}
              onChange={handleChange}
              label=""
              inputProps={{ maxLength: 10 }}
              className='textField'
            />
          </Grid>

          <Grid className='gridComponente'>
            <Typography variant="h6" className='titleTypography'>
              Quantidade <span className='asterisk'>*</span>
            </Typography>
            <TextField
              size='small'
              required
              name="quantidade"
              value={formData.quantidade || ''}
              onChange={handleChange}
              label=""
              inputProps={{ maxLength: 2 }}
              className='textField'
            />
          </Grid>

          <Grid className='gridComponente'>
            <Typography variant="h6" className='titleTypography'>
              Operadora <span className='asterisk'>*</span>
            </Typography>
            <TextField
              size='small'
              required
              name="operadora"
              value={formData.operadora || ''}
              onChange={handleChange}
              label=""
              inputProps={{ maxLength: 20 }}
              className='textField'
            />
          </Grid>
        </div>
        <div className='divButtons'>
          <Button
            className='buttonSaveNext'
            variant="contained"
            type="submit"
            disabled={lineExists}
          >
            {title === 'Editar linha' ? 'SALVAR' : 'ADICIONAR LINHA'}
          </Button>
          <Button
            className='buttonSaveExit'
            variant="contained"
            type="button"
            onClick={onCancel}
          >
            CANCELAR
          </Button>
          {onDelete && (
            <Button
              className='buttonDelete'
              variant="contained"
              type="button"
              onClick={confirmDeleteLinha}
            >
              EXCLUIR LINHA
            </Button>
          )}
        </div>
      </form>

      <Modal
        open={isCancelLinhaModalOpen}
        onClose={handleCloseModalLinha}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div className="modalContainer">
          <FiAlertCircle
            size={48}
            color='#B91C1C'
            style={{
              marginBottom: 8,
            }}
          />
          <Typography className="modalTitle" variant="h6">
            Deseja excluir esta linha?
          </Typography>
          <Typography className="modalSubtitle" >
            Essa ação não pode ser desfeita.
          </Typography>
          <div className="modalButtons">
            <Button size='small' variant="contained" color="error" onClick={handleDeleteLinha} className="modal-button-delete">
              Excluir linha
            </Button>
            <Button size='small' variant="contained" color="primary" onClick={handleCloseModalLinha} className="modal-button-cancel">
              Cancelar
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ValeTransporteForm;
