import React, { useState, useContext } from 'react';
import { FichaContext } from '../../../../context/FichaContext';
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  MenuItem,
  Select,
  InputLabel
} from '@mui/material';
import { Ficha } from '../typesFicha';
import './Componentes.css';

interface Props {
  onSave: (componentName: string, data: Partial<Ficha>) => void;
  onExit: (componentName: string, data: Partial<Ficha>) => void;
  setLoading: (isLoading: boolean) => void;
  isLoading: boolean;
}

const DadosBancarios: React.FC<Props> = ({ onSave, onExit, setLoading, isLoading }) => {
  const fichaContext = useContext(FichaContext);

  const initialFormData = {
    possuiConta: fichaContext?.ficha.bancoPagamento ? 'Sim' : 'Não',
    bancoPagamento: fichaContext?.ficha.bancoPagamento || '',
    agenciaPagamento: fichaContext?.ficha.agenciaPagamento || '',
    numeroConta: fichaContext?.ficha.numeroConta || '',
    operacaoBancaria: fichaContext?.ficha.operacaoBancaria || ''
  };

  const [formData, setFormData] = useState(initialFormData);

  if (!fichaContext) {
    return null;
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleSelectChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name as string]: value
    }));
  };

  const handleSave = () => {
    onSave('dadosBancarios', formData);
  };

  const handleExit = () => {
    onExit('onExit', formData);
  };

  const areFieldsFilled = () => {
    const { possuiConta, bancoPagamento, agenciaPagamento, numeroConta, operacaoBancaria } = formData;
    if (possuiConta === 'Sim') {
      return bancoPagamento !== '' && agenciaPagamento !== '' && numeroConta !== '' && operacaoBancaria !== '';
    } else {
      return true;
    }
  };

  return (
    <>
      <Grid style={{ marginRight: 16, marginLeft: 16, marginBottom: 16 }}>
        <Typography variant="h6" className="titleComponente">
          Dados bancários
        </Typography>
        <Typography variant="body2" className="descriptionComponente">
          Etapa obrigatória <span className='asterisk'>*</span>
        </Typography>
      </Grid>
      <form className="formComponente">

        <Grid className='gridComponente'>
          <FormControl className='formControl' component="fieldset" required>
            <Typography variant="h6" className='titleTypography'>
              Possui conta na Caixa ou Bradesco? <span className='asterisk'>*</span>
            </Typography>
            <RadioGroup
              aria-label="Possui conta na Caixa ou Bradesco?"
              name="possuiConta"
              value={formData.possuiConta}
              onChange={handleChange}
              row
            >
              <FormControlLabel value="Sim" control={<Radio />} label="Sim" />
              <FormControlLabel value="Não" control={<Radio />} label="Não" />
            </RadioGroup>
          </FormControl>
        </Grid>

        {formData.possuiConta === 'Sim' && (
          <>
            <Grid className='gridComponente'>
              <Typography variant="h6" className='titleTypography'>
                Banco de pagamento <span className='asterisk'>*</span>
              </Typography>
              <FormControl className='formControl' component="fieldset">
                <Select
                  id="bancoPagamento"
                  name="bancoPagamento"
                  className='selectField'
                  size='small'
                  label=""
                  required
                  value={formData.bancoPagamento}
                  onChange={(e) => handleSelectChange(e as React.ChangeEvent<{ name?: string; value: unknown }>)}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Selecione...
                  </MenuItem>
                  <MenuItem value="Bradesco S/A">Bradesco S/A</MenuItem>
                  <MenuItem value="Caixa Economica Federal">Caixa Economica Federal</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid className='gridComponente'>
              <Typography variant="h6" className='titleTypography'>
                Agência <span className='asterisk'>*</span>
              </Typography>
              <TextField
                size='small'
                label=""
                inputProps={{ maxLength: 50 }}
                name="agenciaPagamento"
                value={formData.agenciaPagamento}
                onChange={handleChange}
                className='textField'
                required
              />
            </Grid>

            <Grid className='gridComponente'>
              <Typography variant="h6" className='titleTypography'>
                Número da conta <span className='asterisk'>*</span>
              </Typography>
              <TextField
                size='small'
                className='textField'
                label=""
                inputProps={{ maxLength: 50 }}
                name="numeroConta"
                value={formData.numeroConta}
                onChange={handleChange}
                required
              />
            </Grid>

            <Grid className='gridComponente'>
              <Typography variant="h6" className='titleTypography'>
                Operação bancária/tipo de Conta <span className='asterisk'>*</span>
              </Typography>
              <FormControl className='formControl' component="fieldset">
                <Select
                  id="operacaoBancaria"
                  name="operacaoBancaria"
                  className='selectField'
                  size='small'
                  label=""
                  value={formData.operacaoBancaria}
                  onChange={(e) => handleSelectChange(e as React.ChangeEvent<{ name?: string; value: unknown }>)}
                  required
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Selecione...
                  </MenuItem>
                  <MenuItem value="Conta corrente">Conta corrente</MenuItem>
                  <MenuItem value="Conta poupança">Conta poupança</MenuItem>
                  <MenuItem value="Conta salário">Conta salário</MenuItem>
                </Select>
              </FormControl>
            </Grid>

          </>
        )}
      </form>
      <div className="divButtons">
        <Button
          className="buttonSaveNext"
          variant="contained"
          onClick={handleSave}
          disabled={!areFieldsFilled() || isLoading}
        >
          Próxima Etapa
        </Button>
        <Button className="buttonSaveExit" variant="contained" onClick={handleExit}
          disabled={!areFieldsFilled() || isLoading}>
          Salvar e Continuar Depois
        </Button>
      </div>
    </>
  );
};

export default DadosBancarios;
